<template>
  <div class="newSeeds">
    <!-- title -->
    <div class="title">
      <div class="title-top">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-circle-plus-outline"
          @click="addMessage"
          >新增</el-button
        >
        <div class="el-title-item">
          <el-form ref="form" :model="logisticsForm" label-width="80px">
            <el-form-item label="作物名称">
              <el-input
                v-model="logisticsForm.batchCropName"
                placeholder="请输入作物名"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="title-bottom">
        <div class="title-bottom-left">
          <el-button @click="refresh" icon="el-icon-refresh-right" size="small"
            >刷新</el-button
          >
        </div>
        <div class="title-bottom-right">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="small" @click="removeForm"
            >重置</el-button
          >
        </div>
      </div>
    </div>
    <!-- table -->
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        label="序号"
        type="index"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        align="center"
        label="质检类型"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">自检</span>
          <span v-if="scope.row.type == 1">抽检</span>
        </template>
      </el-table-column>
      <el-table-column
        label="园区名"
        prop="baseName"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="种植名"
        prop="plantingName"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="季节"
        prop="season"
        show-overflow-tooltip
        align="center"
        :formatter="getSeasonName"
      >
      </el-table-column>
      <el-table-column
        label="地块号"
        prop="batchName"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="作物名"
        prop="batchCropName"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="总产量"
        prop="totalOutput"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="存放地"
        prop="finishedStoragePlace"
        show-overflow-tooltip
        align="center"
        width="70"
      >
      </el-table-column>
      <el-table-column
        label="抽检数量"
        prop="samplingInspectionQuantity"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="抽检单位"
        prop="samplingInspectionQuantityUnit"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="等级"
        prop="gradeOfQuality"
        show-overflow-tooltip
        align="center"
        :formatter="getGradeName"
      >
      </el-table-column>
      <el-table-column
        label="数量"
        prop="count"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="数量单位"
        prop="countUnit"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="自检人"
        prop="qualityInspectionPersonnel"
        show-overflow-tooltip
        align="center"
        :formatter="getPersonnelName"
      >
      </el-table-column>
      <el-table-column
        label="负责人"
        prop="principal"
        show-overflow-tooltip
        align="center"
        width="80"
      >
      </el-table-column>
      <el-table-column
        label="时间"
        prop="inspectorTime"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>

      <el-table-column
        label="监督人员"
        prop="supervisoryPersonnel"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="图片"
        prop="img"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <img :src="scope.row.img" width="50" height="50" />
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="updateClick(scope.$index, scope.row)"
          ></el-button> -->
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="del(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 新增 编辑 -->
    <el-dialog
      title=""
      :visible.sync="updateForm"
      width="30vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ boxPrompt }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="upTableData" rules="rules" :model="upTableData" label-width="7vw">
          <!-- 质检类型 -->
          <el-form-item label="质检类型" prop="type">
            <el-select
              @change="changeType"
              v-model="inspectionType"
              placeholder="请选择类型"
              style="width: 21vw"
            >
              <el-option
                :label="item.qualityName"
                :value="item.id"
                v-for="item in qualityList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- 自检 -->
          <el-form-item label="采收批次号" prop="batchName">
            <el-select
              @change="harvestingEvents"
              v-model="upTableData.batchName"
              placeholder="请选择批次号"
              style="width: 21vw"
            >
              <el-option
                :label="item.recoveryBatch"
                :value="item"
                v-for="item in purchaseList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 自检/抽检 -->
          <el-form-item label="园区名称" prop="baseName">
            <el-input v-model.trim="upTableData.baseName" disabled></el-input>
          </el-form-item>
          <!-- 自检/抽检 -->
          <el-form-item label="作物名称" prop="batchCropName">
            <el-input
              v-model.trim="upTableData.batchCropName"
              disabled
            ></el-input>
          </el-form-item>
          <!-- 自检/抽检 -->
          <el-form-item label="园区负责人" prop="principal">
            <el-input v-model.trim="upTableData.principal" disabled></el-input>
          </el-form-item>

          <!-- 自检 -->
          <el-form-item v-if="qualityTesting" label="总产量" prop="totalOutput">
            <el-input 
              v-model.trim="upTableData.totalOutput"
              disabled
            ></el-input>
          </el-form-item>
          <!-- 自检 -->
          <el-form-item
            v-if="qualityTesting"
            label="总产量单位"
            prop="totalOutputUnit"
          >
            <el-input v-model.trim="upTableData.totalOutputUnit"></el-input>
          </el-form-item>

          <!-- 自检/抽检 -->
          <el-form-item label="种植户名" prop="plantingName">
            <el-input v-model.trim="upTableData.plantingName"></el-input>
          </el-form-item>
          <!-- 自检/抽检 -->
          <el-form-item label="成品存放地" prop="finishedStoragePlace">
            <el-input
              v-model.trim="upTableData.finishedStoragePlace"
            ></el-input>
          </el-form-item>
          <!-- 自检/抽检 -->
          <el-form-item label="抽检数量" prop="samplingInspectionQuantity">
            <el-input type="number"
              v-model.trim="upTableData.samplingInspectionQuantity"
            ></el-input>
          </el-form-item>
          <!-- 自检/抽检 -->
          <el-form-item label="抽检单位" prop="samplingInspectionQuantityUnit">
            <el-input
              v-model.trim="upTableData.samplingInspectionQuantityUnit"
            ></el-input>
          </el-form-item>
          <!-- 自检/抽检 -->
          <el-form-item label="质量等级" prop="gradeOfQuality">
            <el-select
              v-model="upTableData.gradeOfQuality"
              placeholder="请选择等级"
              style="width: 21vw"
            >
              <el-option
                :label="item.gradeName"
                :value="item.id"
                v-for="item in gradeList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 自检/抽检 -->
          <el-form-item label="数量" prop="count">
            <el-input type="number" v-model.trim="upTableData.count"></el-input>
          </el-form-item>
          <!-- 自检/抽检 -->
          <el-form-item label="数量单位" prop="countUnit">
            <el-input v-model.trim="upTableData.countUnit"></el-input>
          </el-form-item>

          <!-- 自检 -->
          <el-form-item v-if="qualityTesting" label="季节" prop="season">
            <el-select
              v-model="upTableData.season"
              placeholder="请选择季节"
              style="width: 21vw"
            >
              <el-option
                :label="item.seasonName"
                :value="item.id"
                v-for="item in seasonList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 自检 -->
          <el-form-item
            v-if="qualityTesting"
            label="自检人员"
            prop="qualityInspectionPersonnel"
          >
            <el-select
              v-model="upTableData.qualityInspectionPersonnel"
              placeholder="请选择人员"
              style="width: 21vw"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in personnelList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- 抽检 -->
          <el-form-item
            v-if="!qualityTesting"
            label="监督人员"
            prop="supervisoryPersonnel"
          >
            <el-input
              v-model.trim="upTableData.supervisoryPersonnel"
            ></el-input>
          </el-form-item>

          <!-- 自检/抽检 -->
          <el-form-item label="时间" prop="inspectorTime">
            <el-date-picker
              v-model="upTableData.inspectorTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 20vw"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 自检/抽检 -->
          <el-form-item label="图片" prop="img">
            <el-upload
              class="avatar-uploader"
              action="/imgUpdata"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="upTableData.img"
                :src="upTableData.img"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item size="large">
            <el-button
              class="saveBtn"
              type="primary"
              @click="saveForm('upTableData')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      upTableData: {
        baseName: "", // 园区名称
        plantingName: "", // 种植户名
        season: "", // 季节
        batchName: "", // 地块号
        batchId: "", // 采收批次Id
        batchCropName: "", // 种植作物名称
        totalOutput: "", // 总产量
        finishedStoragePlace: "", // 成品存放地
        samplingInspectionQuantity: "", // 抽检数量
        samplingInspectionQuantityUnit: "", // 抽检数量单位
        gradeOfQuality: "", // 质量等级
        count: "", // 数量
        countUnit: "", // 数量单位
        supervisoryPersonnel: "", // 监督人员
        qualityInspectionPersonnel: "", // 自检人员
        principal: "", // 园区负责人
        inspectorTime: "", // 质检时间
        img: "",
      },
      inspectionType: 0, // 质检类型
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      searchStatus: false,
      logisticsForm: {
        batchCropName: "",
        region: -1,
      },
      updateForm: false,
      qualityTesting: true,
      boxPrompt: "",
      index: 0,
      qualityList: [
        {
          id: 0,
          qualityName: "自检",
        },
        {
          id: 1,
          qualityName: "抽检",
        },
      ],
      seasonList: [
        {
          id: 0,
          seasonName: "春",
        },
        {
          id: 1,
          seasonName: "夏",
        },
        {
          id: 2,
          seasonName: "秋",
        },
        {
          id: 4,
          seasonName: "冬",
        },
      ],
      // rules: {
      //   batchName: [
      //     { required: true, message: '采收批次号不可为空', trigger: ['blur', 'change'] },
      //   ],
      // },
      gradeList: [
        {
          id: 0,
          gradeName: "优",
        },
        {
          id: 1,
          gradeName: "良",
        },
        {
          id: 2,
          gradeName: "次品",
        },
      ],
      personnelList: [],
      purchaseList: [],
      pecartelNameList: [],
      cid: 0,
    };
  },
  mounted() {
    this.requrstList();
    this.getPurchaseList();
    this.getpersonnelList();
    // this.getBatchList();
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.size = val;
      this.requrstList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.requrstList();
    },
    //请求列表数据
    requrstList(where) {
      this.$get("/qualityInspectionRecord/getQualityInspectionRecords", {
        page: this.page,
        limit: this.size,
        batchCropName: where || "",
        batchId: "",
      }).then((res) => {
        // //console.log(res);
        if (res.data.state == "success") {
          this.tableData = [];
          this.total = res.data.count;
          res.data.datas.forEach((item) => {
            if (item.del !== 1) {
              this.tableData.push(item);
            }
          });
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 获取自检人员下拉列表
    getpersonnelList() {
      this.$get(
        "/qualityInspectionRecord/getQualityInspectionPersonnel",
        {}
      ).then((res) => {
        if (res.data.state == "success") {
          let data = res.data.datas;
          this.personnelList = data;
        }
      });
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 质检类型点击事件
    changeType(val) {
      this.upTableData.type = val;
      //console.log(this.upTableData.type);
      // 自检
      if (this.upTableData.type == 0) {
        this.qualityTesting = true;
      }
      // 抽检
      if (this.upTableData.type == 1) {
        this.qualityTesting = false;
      }
    },

    // 采收批次点击事件
    harvestingEvents(e) {
      this.upTableData.batchName = e.recoveryBatch;
      this.upTableData.batchId = e.id;

      this.$get("/qualityInspectionRecord/getPlantingById", {
        plantingId: e.id,
      }).then((res) => {
        if (res.data.state == "success") {
          let data = res.data.datas;
          this.pecartelNameList = data;
          if (this.pecartelNameList.length > 0) {
            this.upTableData.baseName = this.pecartelNameList[0].basename; // 园区名称
            this.upTableData.batchCropName = this.pecartelNameList[0].plantname; // 作物名称
            this.upTableData.principal =
              this.pecartelNameList[0].basefunctionary; // 负责人
            this.upTableData.totalOutput =
              this.pecartelNameList[0].harvestyield; // 总产量
          }
        }
      });
    },
    getSeasonName(row) {
      let season = row.season;
      let seasonList = this.seasonList;
      for (var i = 0; i < seasonList.length; i++) {
        if (seasonList[i].id == season) {
          return seasonList[i].seasonName;
        }
      }
    },
    getGradeName(row) {
      let gradeOfQuality = row.gradeOfQuality;
      let gradeList = this.gradeList;
      for (var x = 0; x < gradeList.length; x++) {
        if (gradeList[x].id == gradeOfQuality) {
          return gradeList[x].gradeName;
        }
      }
    },
    getPersonnelName(row) {
      let qualityInspectionPersonnel = row.qualityInspectionPersonnel;
      let personnelList = this.personnelList;
      for (var m = 0; m < personnelList.length; m++) {
        if (personnelList[m].id == qualityInspectionPersonnel) {
          return personnelList[m].name;
        }
      }
    },
    // 编辑
    // updateClick(param1, param2) {
    //   // //console.log(param2);
    //   this.boxPrompt = "编辑质检记录";
    //   this.updateForm = true;
    //   this.$nextTick(() => {
    //     this.upTableData = JSON.parse(JSON.stringify(param2));
    //   });
    // },
    // 新增
    addMessage() {
      this.boxPrompt = "新增质检记录";
      this.updateForm = true;
      this.qualityTesting = true;
      (this.upTableData = {
        baseName: "",
        plantingName: "",
        season: "",
        batchName: "",
        batchId: "",
        batchCropName: "",
        totalOutput: "",
        finishedStoragePlace: "",
        samplingInspectionQuantity: "",
        samplingInspectionQuantityUnit: "",
        gradeOfQuality: "",
        count: "",
        countUnit: "",
        supervisoryPersonnel: "",
        qualityInspectionPersonnel: "",
        principal: "",
        inspectorTime: "",
        img: "",
      }),
        (this.inspectionType = 0);
    },
    //删除
    del(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get(
            "/qualityInspectionRecord/deleteQualityInspectionRecordById",
            {
              id: item.id,
            }
          ).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.requrstList();
            } else {
              this.$message.info(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    recoveryUse(data) {
      this.$get("/userManage/updateUserInfoTwo", data).then((res) => {
        //console.log(res);
        this.requrstList();
      });
    },
    //保存
    saveForm() {
      this.$refs["upTableData"].validate((valid) => {
        if (valid) {
          let upTableData = this.upTableData;
          let url =
            "/qualityInspectionRecord/addOrupdateQualityInspectionRecord";
          upTableData.type = this.inspectionType;
          if (this.upTableData.batchName == "") {
            return this.$message.error("采收批次号不能为空！");
          } else if (this.upTableData.plantingName == "") {
            return this.$message.error("种植户名不能为空！");
          } else if (this.upTableData.finishedStoragePlace == "") {
            return this.$message.error("成品存放地不能为空！");
          } else if (this.upTableData.samplingInspectionQuantity == "") {
            return this.$message.error("抽检数量不能为空！");
          } else if (this.upTableData.samplingInspectionQuantityUnit == "") {
            return this.$message.error("抽检单位不能为空！");
          } else if (this.upTableData.count == "") {
            return this.$message.error("数量不能为空！");
          } else if (
            JSON.stringify(upTableData.gradeOfQuality) == JSON.stringify("")
          ) {
            return this.$message.error(" 请选择质量等级!");
          } else if (this.upTableData.countUnit == "") {
            return this.$message.error(" 数量单位不能为空！");
          } else if (this.upTableData.inspectorTime == "") {
            return this.$message.error("时间不能为空！");
          } else if (this.upTableData.img == "") {
            return this.$message.error("图片不能为空！");
          }
          let newUpData = upTableData;
          if (upTableData.type == 0) {
            delete newUpData.supervisoryPersonnel;
            if (JSON.stringify(upTableData.season) == JSON.stringify("")) {
              return this.$message.error("季节不能为空！");
            } else if (this.upTableData.totalOutputUnit == "") {
              return this.$message.error("总产量单位不能为空");
            } else if (this.upTableData.qualityInspectionPersonnel == "") {
              return this.$message.error("自检人员不能为空");
            }
          }
          if (upTableData.type == 1) {
            if (this.upTableData.supervisoryPersonnel == "") {
              return this.$message.error("监督人员不能为空");
            }
            delete newUpData.season;
            delete newUpData.totalOutput;
            delete newUpData.totalOutputUnit;
            delete newUpData.qualityInspectionPersonnel;
          }

          this.$get(url, newUpData).then((res) => {
            this.updateForm = false;
            // //console.log(res);
            if (res.data.state == "success") {
              this.requrstList();
              this.$message.success("操作成功");
            } else if (res.data.state == 204) {
              this.recoveryUse({ ...upTableData, id: res.data.id });
            } else if (res.data.state == 203) {
              this.$message.info(res.data.msg);
            }
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    //刷新
    refresh() {
      this.loading = true;
      //   this.requrstList();
      if (!this.searchStatus) {
        this.requrstList();
      } else {
        this.search();
      }
    },
    //搜索
    search() {
      let name = this.logisticsForm.batchCropName;
      this.page = 1;
      this.loading = true;
      this.searchStatus = true;
      if (name == "" || !name) {
        this.searchStatus = false;
      }
      this.requrstList(name);
    },
    //重置
    removeForm() {
      this.loading = true;
      this.logisticsForm.batchCropName = "";
      this.logisticsForm.region = -1;
      this.requrstList();
    },
    // 格式化照片
    beforeAvatarUpload(file) {
      // const fileType = file.type;
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (
      //   fileType === "image/jpg" ||
      //   fileType === "image/png" ||
      //   fileType === "image/jpeg" ||
      //   fileType === "image/bmp" ||
      //   fileType === "image/gif"
      // ) {
      //   if (!isLt2M) {
      //     this.$message.error("上传头像图片大小不能超过 2MB!");
      //   }
      //   return isLt2M;
      // } else {
      //   this.$message.error(
      //     "不是,jpeg,.png,.jpg,.bmp,.gif文件,请上传正确的图片类型"
      //   );
      //   return false;
      // }
    },
    // 照片上传
    handleAvatarSuccess(e) {
      //console.log(e.data.fileName);
      this.upTableData.img = "https://public.half-half.cn/" + e.data.fileName;
    },
    // 获取采购批次
    async getPurchaseList() {
      const { data } = await this.$get("/recoveryManage/querryRecoveryRecord", {
        page: 1,
        size: 1000,
      });
      if (data.state == "success") {
        this.purchaseList = data.datas;
      }
    },
    // 格式化采购批次
    formatterBatch(row) {
      //console.log(row);
      let batchName = row.batchName;
      let purchaseList = this.purchaseList;
      for (var x = 0; x < purchaseList.length; x++) {
        if (purchaseList[x].id == batchName) {
          return purchaseList[x].recoveryBatch;
        }
        //console.log(purchaseList[x].recoveryBatch)
      }
    },
  },
};
</script>
<style scoped lang="less">
@import "@/style/newBackTable.less";
.operation {
  display: flex;
  justify-content: center;
}

.avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.avatar-uploader img {
  width: 100px;
  height: 100px;
}

.el-table::-webkit-scrollbar {
  width: 6px;
  height: 6px !important;
}
</style>